<template>
  <BaseList
    :list-query="materialSubTypes"
    route="materialSubType"
    locale-section="pages.materialSubTypes"
  />
</template>

<script>
import gql from "graphql-tag";

export default {
  name: "MaterialSubTypesList",
  components: {
    BaseList: () => import("@/components/BaseList")
  },
  data() {
    return {
      materialSubTypes: gql`
        query materialsSubTypes {
          list: materialSubTypes {
            id
            name: subType
            subType
          }
        }
      `
    };
  }
};
</script>
